import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CallButton = withStyles({
    root: {
        textTransform: "none",
        height: 48,
        padding: "0 16px",
        fontSize: 14,
        lineHeight: "48px",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        borderRadius: 8,
        color: "#f4f4f6",
        textAlign: "center",
        minWidth: 168,
        background: "linear-gradient(to right, #616acb, #3943a2)",
        transform: "scale(1)",
        boxShadow: "0 5px 6px rgba(0, 0, 0, 0.05)",
        transition: "all 0.6s cubic-bezier(0.87, 0, 0.13, 1)",
        "&:hover": {
            filter: "brightness(1.3)",
            boxShadow: "0 8px 9px rgba(0, 0, 0, 0.05)",
        },
        "&:active": {
            transform: "scale(0.9)",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        },
        "&:focus": {
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        },
    },
})(Button);

export default CallButton;

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "./shared.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";

import Header from "./Header";
import Index from "./Home";
import Contact from "./Contact";

const theme = createMuiTheme({
  shape: { borderRadius: 3 },
  palette: {
    primary: {
      light: "#616acb",
      main: "#4854bb",
      dark: "#3943a2",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#3943a2",
      dark: "#2f3885",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
  /*background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",*/
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

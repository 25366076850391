import React from "react-dom";
import { Link } from "react-router-dom";

import styles from "./style.module.css";

const hook = (_) => {
  return (
    <header className={styles.header}>
      <nav className={styles.centerNav}>
        <Link to="/" className={styles.centerNavLink}>Home</Link>
        <Link to="/contact" className={styles.centerNavLink}>Contact</Link>
      </nav>
    </header>
  );
};
export default hook;

import React, { useEffect, useState } from "react";
import styles from "./style.module.css";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faRedditAlien,
  faTwitch,
  faTwitter,
  faSteam,
} from "@fortawesome/free-brands-svg-icons";

import CallButton from "./components/CallButton";

const Hook = (_) => {
  const [currentScroll, setCurrentScroll] = useState(
    Number(document.body.scrollTop || document.documentElement.scrollTop)
  );
  useEffect(
    (_) => {
      const CheckScroll = (_) => {
        if (
          currentScroll ===
          Number(document.body.scrollTop || document.documentElement.scrollTop)
        )
          return;
        setCurrentScroll(
          Math.min(
            Number(
              document.body.scrollTop || document.documentElement.scrollTop
            ),
            500
          )
        );
      };
      window.addEventListener("scroll", CheckScroll);
      return (_) => {
        window.removeEventListener("scroll", CheckScroll);
      };
    },
    [currentScroll, setCurrentScroll]
  );
  const scaleFactor = 140 / 500;
  return (
    <main className={styles.main}>
      <section
        className={`${styles.hero} ${
          currentScroll < 500 ? styles.heroSticky : ""
        }`}
      >
        <div className={styles.heroWrapper}>
          <div
            className={styles.heroContent}
            style={{
              transformOrigin: "center center",
              transform:
                "scale(" + (1 - currentScroll * 0.00035).toString() + ")",
            }}
          >
            <h1 className={styles.heroContentTitle}>
              Setup<br />
              Grow<br />
              Engage
            </h1>
            <CallButton
              color="primary"
              component={Link}
              to="/contact"
              variant="contained"
              disableElevation
            >
              Contact Sales
            </CallButton>
          </div>
          <div className={styles.heroBackground}>
            <div
              className={styles.heroBackgroundWrapper}
              style={{
                height: window.innerHeight - currentScroll * scaleFactor + 60,
                width: window.innerWidth - currentScroll * scaleFactor + 60,
              }}
            >
              <video
                src="/img/hero_uhd.mp4"
                autoPlay
                muted
                loop
                className={styles.heroBackgroundVideo}
              >
                Your Browser Does Not Support Video
              </video>
              <div className={styles.heroBackgroundOverlay}></div>
            </div>
          </div>
        </div>
      </section>
      <article className={styles.mainPage}>
        <section className={styles.socialsSection}>
          <div className={styles.socialSectionContainer}>
            <FontAwesomeIcon
              icon={faDiscord}
              className={styles.socialSectionContainerIcon}
            />
            <span className={styles.socialSectionContainerText}>Discord</span>
          </div>
          <div className={styles.socialSectionContainer}>
            <FontAwesomeIcon
              icon={faTwitter}
              className={styles.socialSectionContainerIcon}
            />
            <span className={styles.socialSectionContainerText}>Twitter</span>
          </div>
          <div className={styles.socialSectionContainer}>
            <FontAwesomeIcon
              icon={faSteam}
              className={styles.socialSectionContainerIcon}
            />
            <span className={styles.socialSectionContainerText}>Steam</span>
          </div>
          <div className={styles.socialSectionContainer}>
            <FontAwesomeIcon
              icon={faTwitch}
              className={styles.socialSectionContainerIcon}
            />
            <span className={styles.socialSectionContainerText}>Twitch</span>
          </div>
          <div className={styles.socialSectionContainer}>
            <FontAwesomeIcon
              icon={faRedditAlien}
              className={styles.socialSectionContainerIcon}
            />
            <span className={styles.socialSectionContainerText}>Reddit</span>
          </div>
        </section>
        <section className={styles.CTASection}>
          <h1>Ready To Boost Your Community?</h1>
          <CallButton
            color="primary"
            component={Link}
            to="/contact"
            variant="contained"
            disableElevation
          >
            Talk To Us
          </CallButton>
        </section>
        <footer>
          <div>
            Copyright &copy; 2021 Facilitate Community - a wholly owned and
            operated subsidiary of&nbsp;
            <a
              href="https://ibbsenterprisesinc.com"
              target="_blank"
              rel="noreferrer"
            >
              [BZ] Marvin T. Ibbs & Co Enterprises Inc
            </a>
          </div>
          <nav>
            <CallButton
              color="primary"
              component={Link}
              to="/contact"
              variant="contained"
              disableElevation
            >
              Contact
            </CallButton>
          </nav>
        </footer>
      </article>
    </main>
  );
};
export default Hook;

import styles from "./style.module.css";
import { ReactComponent as SendIcon } from "./send.svg";

const Hook = (_) => {
  return (
    <main className={styles.main}>
      <SendIcon className={styles.image} />
      <a
        href="mailto:fcgroup@ibbsenterprisesinc.com"
        target="_blank"
        rel="noreferrer"
      >
        fcgroup@ibbsenterprisesinc.com
      </a>
    </main>
  );
};
export default Hook;
